export const getDateFromString = (val, optimizedForSafari) => {
  let d = optimizedForSafari ? new Date(val.replace(/-/g, '/')) : val;
  let day = getCorrectTimeValue(d.getDate());
  let month = getCorrectTimeValue(d.getMonth() + 1);
  let year = d.getFullYear();
  let hours = getCorrectTimeValue(d.getHours());
  let minutes = getCorrectTimeValue(d.getMinutes());

  return {
    date: `${day}.${month}.${year}`,
    time: `${hours}:${minutes}`,
  };
};

export const getCorrectTimeValue = (val) => {
  return val < 10 ? `0${val}` : val;
};
