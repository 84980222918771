<template>
  <div class="tick-pay-choice">
    <Heading level="4" class="tick-pay-choice__order-title">
      {{ $t(`payment_choice.text_order`) }}</Heading
    >
    <CurrentAuto
      class="tick-pay-choice__title"
      :current-auto="currentAutoTicket"
      camera-status="ticket_found"
      :auto-plate-number="currentAutoTicket.auto_registration_plate"
      :country-auto-registration-plate="currentAutoTicket.country_registration"
    />

    <div class="tick-pay-choice__row-second">
      <div class="tick-pay-choice__pay-method">
        <Heading level="4" class="tick-pay-choice__subtitle">
          {{ $t(`payment_choice.payment_method`) }}
        </Heading>

        <div class="tick-pay-choice__money-btns">
          <ButtonBase
            v-for="item in paymentMethods"
            :key="item.name"
            :class="[
              item.class,
              {
                selected: selectedPaymentMethod === item.name,
              },
            ]"
            size="m"
            color="white"
            @click.native="selectPaymentMethodHandler(item)"
          >
            <svg-icon :name="`${item.name}`"></svg-icon>
            <span class="tick-pay-choice__btn-text">
              {{ $t(`payment_choice.${item.name}`) }}
            </span>
          </ButtonBase>
        </div>
      </div>

      <div class="auto-info__line"></div>

      <div class="tick-pay-choice__approve">
        <Heading level="4" class="tick-pay-choice__subtitle">
          {{ $t(`payment_choice.approving`) }}
        </Heading>

        <div class="tick-pay-choice__email-group">
          <label class="tick-pay-choice__lables">
            {{ $t('payment_choice.email') }}
          </label>
          <TextField
            id="email"
            v-model.trim="driverEmail"
            type="text"
            name="email"
            required
            :placeholder="driverEmail"
            :error="
              ($v.driverEmail.$dirty && !$v.driverEmail.required) ||
              ($v.driverEmail.$dirty && $v.driverEmail.$invalid)
            "
            :error-txt="driverEmailErrorText"
            @isFocus="clearEmailField"
            @blur="$v.driverEmail.$touch()"
          />
        </div>
      </div>
    </div>

    <Heading level="3" class="tick-pay-choice__subtitle-price">
      {{ $t('payment_choice.price') }}
      <span>{{ currentAutoTicket.ticket_price }} грн.</span>
    </Heading>
    <SignupControlButtons
      :back-button-text="$t('payment_choice.back')"
      :go-button-text="$t('payment_choice.next')"
      :is-disabled-go-btn="isDisabledGoBtn"
      :loading-go-btn="loadingPaymentBtn"
      @go-back="goBack"
      @go-on="goToPay"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setToLS, getFromLS, removeFromLS } from '@/library/helpers';
import { required, email } from 'vuelidate/lib/validators';

import Heading from '@/elements/Heading/Heading.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import TextField from '@/elements/Inputs/TextField.vue';

import CurrentAuto from '@/components/CurrentAuto/CurrentAuto.vue';
import SignupControlButtons from '@/components/Signup/SignupControlButtons/SignupControlButtons.vue';

export default {
  components: {
    Heading,
    ButtonBase,
    TextField,
    CurrentAuto,
    SignupControlButtons,
  },
  validations: {
    driverEmail: {
      required,
      email,
    },
  },
  data() {
    return {
      currentAutoTicket: getFromLS('new-ticket'),
      paymentMethods: [
        {
          name: 'credit_card',
          class: 'tick-pay-choice__credit-card',
        },
        {
          name: 'apple_pay',
          class: 'tick-pay-choice__apple-pay',
        },
        {
          name: 'google_pay',
          class: 'tick-pay-choice__google-pay',
        },
      ],
      driverEmail: '',
      selectedPaymentMethod: '',
      manualPayment: 1,
      transactionId: '4cf651fd-09c1-42d7-a394-05691afe823e',
      loadingPaymentBtn: false,
      newTicketId: '',
    };
  },
  computed: {
    ...mapGetters(['getUser']),
    driverEmailErrorText() {
      if (!this.$v.driverEmail.required) {
        return this.$t('input_validation.driver_email.empty');
      }
      if (!this.$v.driverEmail.email) {
        return this.$t('input_validation.driver_email.invalid');
      }

      return '';
    },
    isDisabledGoBtn() {
      return !this.selectedPaymentMethod || this.$v.driverEmail.$invalid;
    },
    newTicketData() {
      const ticket = {
        last_name: this.currentAutoTicket.last_name,
        first_name: this.currentAutoTicket.first_name,
        email: this.driverEmail,
        telephone_number: this.currentAutoTicket.telephone_number,
        type_auto: this.currentAutoTicket.type_auto,
        country_registration_id: this.currentAutoTicket.country_registration.id,
        auto_registration_plate: this.currentAutoTicket.auto_registration_plate,
        car_brand_id: this.currentAutoTicket.car_brand.id,
        car_model_id: this.currentAutoTicket.car_model.id,
        count_of_passengers: this.currentAutoTicket.count_of_passengers,
        manual_payment: this.manualPayment,
        datetime: this.currentAutoTicket.datetime,
        time_interval: this.currentAutoTicket.time_interval,
        customs_post: this.currentAutoTicket.customs_post,
      };

      if (this.currentAutoTicket.second_name) {
        ticket.second_name = this.currentAutoTicket.second_name;
      }

      return ticket;
    },
  },
  created() {
    this.driverEmail = this.getUser?.email;
  },
  methods: {
    clearEmailField() {
      this.$v.driverEmail.$reset();
    },
    goBack() {
      this.$router.push({ name: 'NewTicketDate' });
    },
    selectPaymentMethodHandler(method) {
      this.selectedPaymentMethod = method.name;
    },
    async goToPay() {
      this.loadingPaymentBtn = true;

      const { order_id, url } = await this.$store.dispatch(
        'registerNewTicket',
        this.newTicketData
      );

      setToLS('order_id', order_id);
      window.location.href = url;

      this.loadingPaymentBtn = false;
    },
  },
};
</script>
<style lang="sass">
.tick-pay-choice__order-number
  color: var(--btn-color-blue)

.tick-pay-choice__title
  margin-bottom: 48px

.tick-pay-choice__subtitle
  padding-top: 32px
  margin-bottom: 24px
  @media screen and (max-width: 768px)
    padding-top: 0px
    margin-bottom: 14px!important

.tick-pay-choice__pay-method .tick-pay-choice__subtitle
  margin-bottom: 50px

.tick-pay-choice__subtitle-price.title--3
  text-align: center
  padding-top: 48px
  margin-bottom: 0
  border-top: 1px solid var(--primary-color-light-grey-2)
  @media screen and (max-width: 1199px)
    padding-top: 40px

  @media screen and (max-width: 768px)
    font-size: 40px

  @media screen and (max-width: 560px)
    font-size: 24px
    line-height: 29px
    padding-top: 24px
    margin-top: 0

  span
    color: var(--btn-color-blue)

.tick-pay-choice__row-second
  display: grid
  grid-template-columns: 1fr 100px 1fr
  border-top: 1px solid var(--primary-color-light-grey-2)

  @media screen and (max-width: 1199px)
    grid-template-columns: 1fr 80px 1fr

  @media screen and (max-width: 768px)
    grid-template-columns: 1fr 1fr
    grid-template-rows: 1fr 1fr
    padding-bottom: 40px

  @media screen and (max-width: 670px)
    grid-template-columns: 1.3fr 1fr

  @media screen and (max-width: 560px)
    grid-template-columns: 1fr
    padding-bottom: 0px

.auto-info__line
  height: 154px
  width: 1px
  background-color: var(--primary-color-light-grey-2)
  grid-column: 2 / 3
  grid-row: 1 / -1
  justify-self: center
  align-self: flex-end

  @media screen and (max-width: 1023px)
    display: none

.tick-pay-choice__approve
  grid-column: 3 / 4
  grid-row: 1 / -1
  @media screen and (max-width: 768px)
    grid-column: 1 / 2
    grid-row: 2 / -1

.tick-pay-choice__money-btns
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  gap: 24px

  @media screen and (max-width: 1199px)
    grid-template-columns: 1fr 1fr
    grid-template-rows: 1fr 1fr

  @media screen and (max-width: 768px)
    grid-template-columns: 1fr 1fr 1fr
    grid-template-rows: 1fr
    max-width: 540px

  @media screen and (max-width: 560px)
    grid-template-columns: 1fr 1fr
    grid-template-rows: 1fr 1fr
    gap: 16px

.tick-pay-choice__google-pay.primary-btn span,
.tick-pay-choice__apple-pay.primary-btn span
  display: none

.tick-pay-choice__google-pay.primary-btn,
.tick-pay-choice__apple-pay.primary-btn
  max-width: 120px
  width: 100%

  @media screen and (max-width: 1199px)
    min-width: 100%

  @media screen and (max-width: 560px)
    height: 56px!important

.tick-pay-choice__google-pay.primary-btn svg,
.tick-pay-choice__apple-pay.primary-btn svg
  width: 54px
  height: 50px
  @media screen and (max-width: 560px)
    width: 40px
    height: 20px

.tick-pay-choice__btn-text
  padding-left: 15px
  font-size: 24px!important
  text-transform: unset!important

.tick-pay-choice__pay-method
  @media screen and (max-width: 768px)
    grid-column: 1 / -1

.tick-pay-choice__credit-card.primary-btn--medium
  width: 100%!important
  color: var(--btn-color-blue)!important
  text-transform: unset!important
  padding: 0 20px!important
  white-space: nowrap
  @media screen and (max-width: 560px)
    height: 56px!important

.tick-pay-choice__credit-card
  @media screen and (max-width: 1199px)
    grid-column: 1 / -1
    grid-row: 2 / 3

  @media screen and (max-width: 768px)
    grid-column: 3 / -1
    grid-row: 1 / -1

  @media screen and (max-width: 560px)
    grid-column: 1 / -1
    grid-row: 2 / 3

.tick-pay-choice__money-btns .primary-btn
  border: 1px solid var(--primary-color-light-grey-2)!important
  box-shadow: 0px 3px 4px rgba(148, 157, 172, 0.12)!important

.tick-pay-choice__money-btns .primary-btn.selected,
.tick-pay-choice__money-btns .primary-btn:hover
  border: 1px solid var(--btn-color-blue)!important

.tick-pay-choice__credit-card.primary-btn--medium span
  @media screen and (max-width: 560px)
    font-size: 16px!important

.tick-pay-choice__apple-pay
  @media screen and (max-width: 1199px)
    width: 100%

.tick-pay-choice__email-group
  display: flex
  flex-direction: column
  gap: 8px

.tick-pay-choice__lables
  color: var(--primary-color-dark-blue)
  opacity: 0.6

.tick-pay-choice__email-group .input-box input
  @media screen and (max-width: 560px)
    height: 56px !important
</style>
