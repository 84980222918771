var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auto-info auto-info__wrapper",class:_vm.wrapperCssClasses},[_c('div',{staticClass:"auto-info__header"},[_c('div',{staticClass:"auto-info__number"},[_c('div',{staticClass:"auto-info__flag"},[_c('img',{attrs:{"src":("/images/flags/" + _vm.getCountryAutoRegistration + ".svg"),"alt":""}})]),_c('span',[_vm._v(" "+_vm._s(_vm.autoPlateNumber)+" ")])]),(!_vm.isPaymentChoicePage && !_vm.isPaidPage)?_c('div',[(_vm.cameraStatus === 'ticket_found')?_c('div',{staticClass:"auto-info__ticket-status"},[_vm._v(" "+_vm._s(_vm.$t('payment_choice.payment_wait'))+" ")]):_c('ButtonBase',{staticClass:"auto-info__ticket-buy",attrs:{"color":"blue","size":"m"},nativeOn:{"click":function($event){return _vm.goToNewTicket.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.ticket_to_buy'))+" ")])],1):_vm._e()]),_c('div',{staticClass:"auto-info__content",class:{ 'auto-info__content--small': _vm.getDriverName.length >= 27 }},[_c('div',{staticClass:"auto-info__brand"},[_c('div',{staticClass:"auto-info__titles"},[_vm._v(_vm._s(_vm.$t('dashboard.brand')))]),_c('div',{staticClass:"auto-info__values",class:{
          'auto-info__values--not':
            !_vm.currentAuto || !!!_vm.currentAuto.car_brand.title,
        }},[_vm._v(" "+_vm._s(_vm.getCarBrand)+" ")])]),_c('div',{staticClass:"auto-info__model"},[_c('div',{staticClass:"auto-info__titles"},[_vm._v(_vm._s(_vm.$t('dashboard.model')))]),_c('div',{staticClass:"auto-info__values",class:{
          'auto-info__values--not':
            !_vm.currentAuto || !!!_vm.currentAuto.car_model.title,
        }},[_vm._v(" "+_vm._s(_vm.getCarModel)+" ")])]),_c('div',{staticClass:"auto-info__auto-type"},[_c('div',{staticClass:"auto-info__titles"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.type_of_auto_title"))+" ")]),_c('div',{staticClass:"auto-info__values",class:{
          'auto-info__values--not': !_vm.currentAuto || !!!_vm.currentAuto.type_auto,
        }},[_vm._v(" "+_vm._s(_vm.getTypeAuto)+" ")])]),_c('div',{staticClass:"auto-info__line"}),_c('div',{staticClass:"auto-info__passangers"},[_c('div',{staticClass:"auto-info__titles"},[_vm._v(_vm._s(_vm.$t('dashboard.passangers')))]),_c('div',{staticClass:"auto-info__values",class:{
          'auto-info__values--not':
            !_vm.currentAuto || !!!_vm.currentAuto.count_of_passengers,
        }},[_vm._v(" "+_vm._s(_vm.getCountOfPassengers)+" ")])]),_c('div',{staticClass:"auto-info__driver"},[_c('div',{staticClass:"auto-info__titles"},[_vm._v(_vm._s(_vm.$t('dashboard.driver')))]),_c('div',{staticClass:"auto-info__driver-name",class:{
          'auto-info__values--not': !_vm.currentAuto || !!!_vm.getDriverName,
        }},[_vm._v(" "+_vm._s(_vm.getDriverName)+" ")])]),_c('div',{staticClass:"auto-info__date"},[_c('div',{staticClass:"auto-info__titles"},[_vm._v(_vm._s(_vm.$t('dashboard.date')))]),_c('div',{staticClass:"auto-info__values",class:{
          'auto-info__values--not': !_vm.currentAuto || !!!_vm.currentAuto.datetime,
        }},[_vm._v(" "+_vm._s(_vm.getDate)+" ")])]),_c('div',{staticClass:"auto-info__time"},[_c('div',{staticClass:"auto-info__titles"},[_vm._v(_vm._s(_vm.$t('dashboard.time')))]),_c('div',{staticClass:"auto-info__values",class:{
          'auto-info__values--not':
            !_vm.currentAuto || !!!_vm.currentAuto.time_interval,
        }},[_vm._v(" "+_vm._s(_vm.gettimeInterval)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }