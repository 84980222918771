<template>
  <div class="auto-info auto-info__wrapper" :class="wrapperCssClasses">
    <div class="auto-info__header">
      <div class="auto-info__number">
        <div class="auto-info__flag">
          <img
            :src="`/images/flags/${getCountryAutoRegistration}.svg`"
            alt=""
          />
        </div>
        <span>
          {{ autoPlateNumber }}
        </span>
      </div>
      <div v-if="!isPaymentChoicePage && !isPaidPage">
        <div
          v-if="cameraStatus === 'ticket_found'"
          class="auto-info__ticket-status"
        >
          {{ $t('payment_choice.payment_wait') }}
        </div>
        <ButtonBase
          v-else
          color="blue"
          size="m"
          class="auto-info__ticket-buy"
          @click.native="goToNewTicket"
        >
          {{ $t('dashboard.ticket_to_buy') }}
        </ButtonBase>
      </div>
    </div>

    <div
      class="auto-info__content"
      :class="{ 'auto-info__content--small': getDriverName.length >= 27 }"
    >
      <div class="auto-info__brand">
        <div class="auto-info__titles">{{ $t('dashboard.brand') }}</div>
        <div
          class="auto-info__values"
          :class="{
            'auto-info__values--not':
              !currentAuto || !!!currentAuto.car_brand.title,
          }"
        >
          {{ getCarBrand }}
        </div>
      </div>

      <div class="auto-info__model">
        <div class="auto-info__titles">{{ $t('dashboard.model') }}</div>
        <div
          class="auto-info__values"
          :class="{
            'auto-info__values--not':
              !currentAuto || !!!currentAuto.car_model.title,
          }"
        >
          {{ getCarModel }}
        </div>
      </div>
      <div class="auto-info__auto-type">
        <div class="auto-info__titles">
          {{ $t(`dashboard.type_of_auto_title`) }}
        </div>
        <div
          class="auto-info__values"
          :class="{
            'auto-info__values--not': !currentAuto || !!!currentAuto.type_auto,
          }"
        >
          {{ getTypeAuto }}
        </div>
      </div>

      <div class="auto-info__line"></div>

      <div class="auto-info__passangers">
        <div class="auto-info__titles">{{ $t('dashboard.passangers') }}</div>
        <div
          class="auto-info__values"
          :class="{
            'auto-info__values--not':
              !currentAuto || !!!currentAuto.count_of_passengers,
          }"
        >
          {{ getCountOfPassengers }}
        </div>
      </div>

      <div class="auto-info__driver">
        <div class="auto-info__titles">{{ $t('dashboard.driver') }}</div>
        <div
          class="auto-info__driver-name"
          :class="{
            'auto-info__values--not': !currentAuto || !!!getDriverName,
          }"
        >
          {{ getDriverName }}
        </div>
      </div>

      <div class="auto-info__date">
        <div class="auto-info__titles">{{ $t('dashboard.date') }}</div>
        <div
          class="auto-info__values"
          :class="{
            'auto-info__values--not': !currentAuto || !!!currentAuto.datetime,
          }"
        >
          {{ getDate }}
        </div>
      </div>

      <div class="auto-info__time">
        <div class="auto-info__titles">{{ $t('dashboard.time') }}</div>
        <div
          class="auto-info__values"
          :class="{
            'auto-info__values--not':
              !currentAuto || !!!currentAuto.time_interval,
          }"
        >
          {{ gettimeInterval }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import { getDateFromString } from '@/library/dateFormat';
export default {
  components: {
    ButtonBase,
  },

  props: {
    currentAuto: {
      required: true,
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
    cameraStatus: {
      type: String,
      required: true,
    },
    autoPlateNumber: {
      type: String,
      required: true,
    },
    countryAutoRegistrationPlate: {
      required: true,
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
  },

  data() {
    return {
      defaultValues: {
        carBrand: '------',
        carModel: '------ ',
        countOfPassengers: '-',
        queueNumber: '--',
        typeAuto: '------',
        driverName: '------ ------ ------',
        date: '--.--.----',
        timeInterval: '--:--',
      },
    };
  },

  computed: {
    getCountryAutoRegistration() {
      return this.countryAutoRegistrationPlate
        ? this.countryAutoRegistrationPlate.iso
        : 'default-flag';
    },
    getCarBrand() {
      return this.currentAuto?.car_brand.title || this.defaultValues.carBrand;
    },

    getCarModel() {
      return this.currentAuto?.car_model.title || this.defaultValues.carModel;
    },

    getCountOfPassengers() {
      return (
        this.currentAuto?.count_of_passengers ||
        this.defaultValues.countOfPassengers
      );
    },

    getQueueNumber() {
      return this.currentAuto ? '20' : this.defaultValues.queueNumber;
    },

    getTypeAuto() {
      return this.currentAuto
        ? this.$t(`dashboard.type_of_auto.${this.currentAuto.type_auto}`)
        : this.defaultValues.typeAuto;
    },

    getDriverName() {
      const secondName =
        this.currentAuto && this.currentAuto.second_name
          ? ` ${this.currentAuto.second_name}`
          : '';

      return this.currentAuto
        ? `${this.currentAuto.last_name} ${this.currentAuto.first_name}${secondName}`
        : this.defaultValues.driverName;
    },

    getDate() {
      return this.currentAuto?.datetime
        ? getDateFromString(this.currentAuto.datetime, true).date
        : this.defaultValues.date;
    },

    gettimeInterval() {
      return this.currentAuto?.time_interval
        ? this.currentAuto.time_interval
        : this.defaultValues.timeInterval;
    },

    isPaymentChoicePage() {
      return this.$route.name === 'NewTicketPaymentChoice';
    },
    isPaidPage() {
      return this.$route.name === 'NewTicketPaid';
    },
    wrapperCssClasses() {
      return {
        'is-no-ticket': this.cameraStatus === 'ticket_not_found',
        'payment-choice-page': this.isPaymentChoicePage,
        paid: this.isPaidPage,
      };
    },
  },
  methods: {
    goToNewTicket() {
      const carNumber =
        this.getCountryAutoRegistration === 'default-flag'
          ? {
              country: {
                id: 223,
              },
              value: this.autoPlateNumber,
            }
          : {
              country: {
                id: this.countryAutoRegistrationPlate.id,
              },
              value: this.autoPlateNumber,
            };

      this.$router.push({
        name: 'NewTicketInfo',
        params: {
          carNumber,
        },
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.auto-info__wrapper
  padding: 42px 42px 0
  border-radius: var(--primary-border-radius)
  margin-bottom: 70px
  border: 1px solid rgba(40, 59, 89, 0.3)

  @media screen and (max-width: 710px)
    padding: 42px 16px 0
    margin-bottom: 24px

.auto-info__wrapper.is-no-ticket
  border-color: var(--border-color-blue)

  .auto-info__model:after
    background-color: var(--border-color-blue)

.auto-info__wrapper.paid
  border: 1px solid rgba(9, 163, 86, 0.4)
  .auto-info__model:after
    background-color: var(--primary-color-success)
    opacity: 0.4

.auto-info__header
  display: flex
  justify-content: space-between
  @media screen and (max-width: 710px)
    flex-direction: column
    gap: 16px

.auto-info__number
  display: flex
  align-items: center
  gap: 15px
  font-size: 32px
  font-weight: 600
  color: var(--primary-color-dark-blue)

  @media screen and (max-width: 710px)
    font-size: 24px
    line-height: 32px

.auto-info__flag
  display: flex

  img
    width: 40px
    height: 27px

    @media screen and (max-width: 710px)
      width: 32px
      height: 22px

.auto-info__ticket-buy
  height: 40px !important
  font-size: 18px !important
  padding: 10px 35px
  border-radius: 2px !important
  text-transform: unset !important

.auto-info__content
  display: grid
  grid-template-columns: 1fr 1fr 100px 1fr 1fr
  grid-template-rows: 56px 92px
  gap: 32px 0
  padding: 32px 0 0

  @media screen and (max-width: 1023px)
    grid-template-columns: 1.12fr 1fr
    grid-template-rows: 1fr 1fr 1fr 1fr
    padding: 32px 0 40px
  @media screen and (max-width: 710px)
    gap: 16px 0
    grid-template-columns: 1.5fr 1fr
    grid-template-rows: 1fr 1fr 1fr 1fr

.auto-info__content--small
  @media screen and (max-width: 415px)
    grid-template-rows: 64px 1fr 1fr 1fr

.auto-info__titles
  font-size: 16px
  line-height: 18.5px
  color: var(--primary-color-dark-blue)
  opacity: 0.6

.auto-info__values,
.auto-info__values--not,
.auto-info__driver-name
  font-size: 22px
  line-height: 32px
  font-weight: 600
  color: var(--primary-color-dark-blue)

  @media screen and (max-width: 1023px)
    font-size: 18px
    line-height: 24px
  @media screen and (max-width: 450px)
    font-size: 16px

.auto-info__values--not
  color: var(--primary-color-dark-grey-2)

.auto-info__ticket-status
  font-weight: 600
  width: 250px
  text-align: center
  background: rgba(214, 160, 19, 0.08)
  color: #C9950E
  padding: 10px
  margin-right: 55px
  @media screen and (max-width: 1023px)
    margin-right: 35px


.auto-info__driver
  grid-column: 1 / 3
  @media screen and (max-width: 1023px)
    grid-row: 4 / 5
  @media screen and (max-width: 710px)
    grid-row: 1 / 2

.auto-info__auto-type,
.auto-info__date
  grid-column: 4 / 5

.auto-info__brand,
.auto-info__auto-type,
.auto-info__date
  @media screen and (max-width: 1023px)
    grid-column: 1 / 2

.auto-info__model
  @media screen and (max-width: 710px)
    grid-column: 1 / -1

.auto-info__passangers
  grid-column: 5 / 6
  grid-row: 1 / 2
  @media screen and (max-width: 1023px)
    grid-column: 2 / 3
    grid-row: 2 / 3
  @media screen and (max-width: 710px)
    grid-row: 4 / 5

.auto-info__line
  height: 144px
  width: 1px
  background-color: var(--primary-color-light-grey-2)
  grid-column: 3 / 4
  grid-row: 1 / -1
  justify-self: center
  align-self: flex-start

  @media screen and (max-width: 1023px)
    display: none
</style>
